import { RibbonBanner, RibbonBannerProps } from '@ifixit/ui-chakra/ribbon-banner';
import { initializeChakraComponent } from 'Shared/chakra-initialize';

function CarpenterRibbonBanner({
   page,
   ribbon,
}: {
   page: string;
   ribbon: RibbonBannerProps['ribbon'];
}) {
   return <RibbonBanner ribbon={ribbon} page={page} />;
}

initializeChakraComponent('RibbonBanner', CarpenterRibbonBanner);
